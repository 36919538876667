/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType } from '@angular/cdk/portal';
import { Injectable, inject } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public readonly snackBar = inject(MatSnackBar);

  success(message: string, config: MatSnackBarConfig<any> = {}) {
    return this.snackBar.open(message, 'X', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['!bg-green-500', '!text-white', 'rounded'],
      duration: 5000,
      ...config,
    });
  }

  error(message: string, config: MatSnackBarConfig<any> = {}) {
    return this.snackBar.open(message, 'X', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['!bg-red-500', 'text-white', 'rounded'],
      duration: 5000,
      ...config,
    });
  }

  openFromComponent(
    component: ComponentType<unknown>,
    config: MatSnackBarConfig<any> = {},
  ) {
    return this.snackBar.openFromComponent(component, {
      duration: 5000,
      ...config,
    });
  }
}
